body {
  font-family: "Montserrat";
  font-size: 1.2rem;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
h3 {
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.logo {
  width: 100%;
}
.smoothing {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  filter: blur(8px);
}
.break-line {
  width: 90%;
  height: 3px;
  color: #cccccc75;
  border-radius: 10px;
  border: none;
  background-color: #cccccc75;
}
h1 {
  padding-left: 3rem;
  font-size: 2rem;
}
.flex-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 75px;
  max-width: 7rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flex-numbers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flex-mid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-col-mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: space-around;
}
.flex-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space;
  align-items: center;
  padding: 15px 25px;
}
.footer {
  background-color: #f4f4f4;
}
.flex-explanation {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
}
.number-explanation {
  text-align: left;
  font-size: 1rem;
}
.explanation {
  text-align: justify;
  line-height: 1.4;
  max-width: 1170px;
  font-size: 1.5rem;
}
.explanation-half {
  text-align: justify;
  line-height: 1.2;
  font-size: 1.5rem;
  width: 45%;
  max-width: 800px;
}
.number {
  font-size: 3rem;
}
.highlight {
  font-weight: 600;
}
.source {
  font-size: 0.85rem;
}
.map {
  width: 100%;
  max-width: 800px;
  height: 500px;
}
.date-picker {
  width: 6vw;
  font-family: "Montserrat";
  min-width: 130px;
  /* padding: 5px; */
  border: 1px solid grey;
  background-color: #f4f4f4;
  border-radius: 5px;
}
.slider {
  width: 35vw;
}
.number-input {
  border: 1px solid grey;
  background-color: #f4f4f4;
  border-radius: 5px;
  font-family: "Montserrat";
  min-width: 50px;
  width: 3vw;
  max-width: 60px;
}
.margin-b {
  margin-bottom: 1.5rem;
}
.margin-b1 {
  margin-bottom: 0.5rem;
}
.margin-b2 {
  margin-bottom: 2rem;
}
.margin-t {
  margin-top: 1.5rem;
}
.mt {
  margin-top: 5px;
}
.margin-r {
  margin-right: 1rem;
}
.chart {
  width: 90%;
  max-width: 1000px;
  height: 400px;
}
.detail-chart {
  width: 50vw;
  max-width: 800px;
  height: 155px;
  margin-bottom: 2%;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
}
.nc-text {
  font-size: 1.5rem;
  text-align: center;
}
.highlight {
  font-weight: 600;
}
.highlight-box {
  text-align: center;
  max-width: 1200px;
  background-color: #f4f4f4;
  line-height: 1.2;
  font-size: 1.5rem;
  padding: 25px;
  border-radius: 5px;
}
.half-chart {
  width: 48vw;
  height: 220px;
  margin-bottom: 1rem;
}
#cx-2020-shift {
  padding: 5px;
  font-size: 1.2rem;
}
#jump-to-bottom {
  font-size: 1rem;
}
#link-button {
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 1;
}
#short-explanation {
  text-align: center;
}
.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  border: none;
  border-radius: 5px;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.collapsible.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.active,
.collapsible:hover {
  background-color: #ccc;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 20px 18px;
  display: none;
  overflow: hidden;
  background-color: #f4f4f4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 720px) {
  /* mobile devices */
  body {
    font-family: "Montserrat";
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 1.2rem;
  }
  .flex-head {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .flex-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 75px;
    max-width: 5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .number-input {
    border: 1px solid grey;
    background-color: #f4f4f4;
    border-radius: 5px;
    font-family: "Montserrat";
    min-width: 50px;
    width: 3vw;
    max-width: 60px;
  }
  .flex-mobile {
    display: flex;
    flex: 100%;
    flex-direction: column;
    align-items: center;
  }
  .map {
    width: 95vw;
    max-width: 800px;
    height: 400px;
    margin-bottom: 1rem;
  }
  .wrapper {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    filter: blur(8px);
  }
  .flex-numbers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .logo {
    width: 100%;
  }
  h1 {
    font-size: 1.5rem;
    width: 80vw;
  }
  .explanation {
    text-align: justify;
    line-height: 1.2;
    max-width: 1170px;
    font-size: 1rem;
  }
  .explanation-half {
    text-align: justify;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .chart {
    width: 100%;
    max-width: 1000px;
    height: 15rem;
  }
  .nc-text {
    font-size: 1.5rem;
    text-align: center;
  }
  .highlight-box {
    text-align: center;
    max-width: 1170px;
    background-color: #f4f4f4;
    line-height: 1.2;
    font-size: 1.5rem;
    padding: 25px;
    border-radius: 5px;
  }
  .margin-b {
    margin-bottom: 1.5rem;
  }
  .margin-t {
    margin-top: 1.5rem;
  }
  .mt {
    margin-top: 5px;
  }
  .margin-r {
    margin-right: 1rem;
  }
  .detail-chart {
    width: 90vw;
    max-width: 800px;
    height: 155px;
    margin-bottom: 2%;
    padding: 5px;
    border: 1px solid #000;
    border-radius: 5px;
  }
  .smoothing {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  .flex-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
  }
  .push {
    margin-left: auto;
  }
  .footer {
    background-color: #f4f4f4;
  }
  #footer-text {
    max-width: 60%;
  }
  .no-break {
    hyphens: none;
  }
  #date-offset {
    font-family: "Montserrat";
    width: 3vw;
  }
  #cx-chart {
    width: 100%;
    height: 200px;
    margin-bottom: 3rem;
  }
  #r-value-chart {
    width: 100%;
    height: 200px;
    margin-bottom: 3rem;
  }
  #link-button {
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 1;
  }
  #k-chart {
    max-height: 15rem;
  }
  #cx-2020-shift {
    padding: 5px;
    font-size: 1.2rem;
  }
  .collapsible {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 5px;
    width: 100%;
    border: none;
    border-radius: 5px;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  .collapsible.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .active,
  .collapsible:hover {
    background-color: #ccc;
  }

  /* Style the collapsible content. Note: hidden by default */
  .content {
    padding: 20px 18px;
    display: none;
    overflow: hidden;
    background-color: #f4f4f4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
