body {
  hyphens: auto;
  -o-hyphens: auto;
  font-family: Montserrat;
  font-size: 1.2rem;
}

h3 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.logo {
  width: 100%;
}

.smoothing {
  margin-bottom: .5rem;
  font-size: .9rem;
}

.wrapper {
  filter: blur(8px);
  padding-left: 1rem;
  padding-right: 1rem;
}

.break-line {
  width: 90%;
  height: 3px;
  color: #cccccc75;
  background-color: #cccccc75;
  border: none;
  border-radius: 10px;
}

h1 {
  padding-left: 3rem;
  font-size: 2rem;
}

.flex-head {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-logo {
  min-width: 75px;
  max-width: 7rem;
  flex-direction: row;
  align-items: center;
  margin-left: .5rem;
  margin-right: .5rem;
  display: flex;
}

.flex, .flex-numbers {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
}

.flex-mid {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-col-mid {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-right {
  flex-direction: row;
  place-content: space-around flex-end;
  display: flex;
}

.flex-footer {
  justify-content: space-between;
  align-content: space;
  flex-direction: row;
  align-items: center;
  padding: 15px 25px;
  display: flex;
}

.footer {
  background-color: #f4f4f4;
}

.flex-explanation {
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
}

.number-explanation {
  text-align: left;
  font-size: 1rem;
}

.explanation {
  text-align: justify;
  max-width: 1170px;
  font-size: 1.5rem;
  line-height: 1.4;
}

.explanation-half {
  text-align: justify;
  width: 45%;
  max-width: 800px;
  font-size: 1.5rem;
  line-height: 1.2;
}

.number {
  font-size: 3rem;
}

.highlight {
  font-weight: 600;
}

.source {
  font-size: .85rem;
}

.map {
  width: 100%;
  max-width: 800px;
  height: 500px;
}

.date-picker {
  width: 6vw;
  min-width: 130px;
  background-color: #f4f4f4;
  border: 1px solid gray;
  border-radius: 5px;
  font-family: Montserrat;
}

.slider {
  width: 35vw;
}

.number-input {
  min-width: 50px;
  width: 3vw;
  max-width: 60px;
  background-color: #f4f4f4;
  border: 1px solid gray;
  border-radius: 5px;
  font-family: Montserrat;
}

.margin-b {
  margin-bottom: 1.5rem;
}

.margin-b1 {
  margin-bottom: .5rem;
}

.margin-b2 {
  margin-bottom: 2rem;
}

.margin-t {
  margin-top: 1.5rem;
}

.mt {
  margin-top: 5px;
}

.margin-r {
  margin-right: 1rem;
}

.chart {
  width: 90%;
  max-width: 1000px;
  height: 400px;
}

.detail-chart {
  width: 50vw;
  max-width: 800px;
  height: 155px;
  border: 1px solid #000;
  border-radius: 5px;
  margin-bottom: 2%;
  padding: 5px;
}

.nc-text {
  text-align: center;
  font-size: 1.5rem;
}

.highlight {
  font-weight: 600;
}

.highlight-box {
  text-align: center;
  max-width: 1200px;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 25px;
  font-size: 1.5rem;
  line-height: 1.2;
}

.half-chart {
  width: 48vw;
  height: 220px;
  margin-bottom: 1rem;
}

#cx-2020-shift {
  padding: 5px;
  font-size: 1.2rem;
}

#jump-to-bottom {
  font-size: 1rem;
}

#link-button {
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 1;
}

#short-explanation {
  text-align: center;
}

.collapsible {
  color: #444;
  cursor: pointer;
  width: 100%;
  text-align: left;
  background-color: #eee;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  font-size: 15px;
}

.collapsible.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.active, .collapsible:hover {
  background-color: #ccc;
}

.content {
  background-color: #f4f4f4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px 18px;
  display: none;
  overflow: hidden;
}

@media screen and (max-width: 720px) {
  body {
    hyphens: auto;
    -o-hyphens: auto;
    font-family: Montserrat;
    font-size: 1.2rem;
  }

  .flex-head {
    flex-flow: wrap;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  .flex-logo {
    min-width: 75px;
    max-width: 5rem;
    flex-direction: row;
    align-items: center;
    margin-left: .5rem;
    margin-right: .5rem;
    display: flex;
  }

  .number-input {
    min-width: 50px;
    width: 3vw;
    max-width: 60px;
    background-color: #f4f4f4;
    border: 1px solid gray;
    border-radius: 5px;
    font-family: Montserrat;
  }

  .flex-mobile {
    flex-direction: column;
    flex: 100%;
    align-items: center;
    display: flex;
  }

  .map {
    width: 95vw;
    max-width: 800px;
    height: 400px;
    margin-bottom: 1rem;
  }

  .wrapper {
    filter: blur(8px);
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .flex-numbers {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .logo {
    width: 100%;
  }

  h1 {
    width: 80vw;
    font-size: 1.5rem;
  }

  .explanation {
    text-align: justify;
    max-width: 1170px;
    font-size: 1rem;
    line-height: 1.2;
  }

  .explanation-half {
    text-align: justify;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .chart {
    width: 100%;
    max-width: 1000px;
    height: 15rem;
  }

  .nc-text {
    text-align: center;
    font-size: 1.5rem;
  }

  .highlight-box {
    text-align: center;
    max-width: 1170px;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 25px;
    font-size: 1.5rem;
    line-height: 1.2;
  }

  .margin-b {
    margin-bottom: 1.5rem;
  }

  .margin-t {
    margin-top: 1.5rem;
  }

  .mt {
    margin-top: 5px;
  }

  .margin-r {
    margin-right: 1rem;
  }

  .detail-chart {
    width: 90vw;
    max-width: 800px;
    height: 155px;
    border: 1px solid #000;
    border-radius: 5px;
    margin-bottom: 2%;
    padding: 5px;
  }

  .smoothing {
    margin-bottom: .5rem;
    font-size: .9rem;
  }

  .flex-footer {
    flex-direction: row;
    align-items: center;
    padding: 15px;
    display: flex;
  }

  .push {
    margin-left: auto;
  }

  .footer {
    background-color: #f4f4f4;
  }

  #footer-text {
    max-width: 60%;
  }

  .no-break {
    hyphens: none;
  }

  #date-offset {
    width: 3vw;
    font-family: Montserrat;
  }

  #cx-chart, #r-value-chart {
    width: 100%;
    height: 200px;
    margin-bottom: 3rem;
  }

  #link-button {
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 1;
  }

  #k-chart {
    max-height: 15rem;
  }

  #cx-2020-shift {
    padding: 5px;
    font-size: 1.2rem;
  }

  .collapsible {
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    background-color: #eee;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 5px;
    font-size: 15px;
  }

  .collapsible.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .active, .collapsible:hover {
    background-color: #ccc;
  }

  .content {
    background-color: #f4f4f4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 20px 18px;
    display: none;
    overflow: hidden;
  }
}

/*# sourceMappingURL=index.1661b6e7.css.map */
